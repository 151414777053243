import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  flex-direction: column;
`;

export const Banner = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    background-color: black;
    background-image: none !important;
  }
`;

export const HeaderWrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  margin-right: 40px;
`;

export const Content = styled.div`
  width: calc(100vw - 82px);
  margin-left: 82px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin-left: 0;
  }
`;
