import React from 'react';

import { Footer, Header, Navigation, MobileNav } from 'components';

import { Container, Banner, HeaderWrap, Content } from './styled';

const FeatureLayout = ({ children, navColor, bannerImage }) => {
  return (
    <Container>
      <Banner
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.8)), url(${bannerImage.url})`,
        }}
      />
      <HeaderWrap>
        <Header color='white' />
      </HeaderWrap>
      <Navigation colorScheme={navColor} />
      <Content>
        {children} <Footer />
      </Content>
      <MobileNav />
    </Container>
  );
};

export default FeatureLayout;
