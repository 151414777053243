import React from 'react';
import { graphql } from 'gatsby';

import FeatureLayout from 'layouts/FeatureLayout';
import { Latest, SEO, TextBody } from 'components';
import { InnerWrapCenter } from 'components/common/Containers/styled';
import { LightScheme } from 'helpers/navColors.js';
import { formatAuthor } from 'helpers/formatLists';
import { TextWrap } from 'layouts/HomeLayout/styled';

import { Banner, Body, ImgWrap, MobileTitle, MobileDetails } from './styled';

const FeatureTemplate = ({ data }) => {
  const { feature, latestFeatures } = data;
  const {
    title,
    body,
    subtitle,
    metaDescription,
    coverImage,
    publishedDate,
    category,
    authors,
    artists,
  } = feature;

  const formattedTitle =
    category.name === 'Review'
      ? `Review: ${artists[0].englishName} - ${title}`
      : title;

  const formattedAuthor = formatAuthor(authors);

  return (
    <FeatureLayout navColor={LightScheme} bannerImage={coverImage}>
      <SEO
        title={title}
        description={metaDescription}
        image={coverImage.url}
        imageShare={coverImage.url}
        isBlogPost
      />

      <Banner>
        <ImgWrap>{coverImage && <img src={coverImage.url} alt='' />}</ImgWrap>

        <TextWrap fontSize='50px'>
          <p className='type'>
            <strong>{formattedAuthor}</strong> <span>|</span>
            {publishedDate}
          </p>
          <h1>{formattedTitle}</h1>
        </TextWrap>
      </Banner>
      <MobileDetails>
        <div>
          <p>{formattedAuthor}</p>
          <p>{category.name}</p>
        </div>
        <p>{publishedDate}</p>
      </MobileDetails>
      <MobileTitle>
        <h1>{title}</h1>
      </MobileTitle>
      <Body>
        <TextBody
          body={body}
          subtitle={subtitle}
          artists={artists}
          authors={authors}
        />
        <InnerWrapCenter>
          <Latest
            data={latestFeatures}
            sectionTitle='features'
            link='/features'
          />
        </InnerWrapCenter>
      </Body>
    </FeatureLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    feature: strapiFeatures(slug: { eq: $slug }) {
      title
      subtitle
      metaDescription
      body
      publishedDate(formatString: "MMMM D YYYY")
      authors {
        englishName
        slug
      }
      artists {
        englishName
        slug
      }
      category {
        name
      }
      coverImage {
        url
      }
    }
    latestFeatures: allStrapiFeatures(
      sort: { fields: publishedDate, order: DESC }
      filter: { subcategory: { name: { ne: "Selector" } }, slug: { ne: $slug } }
      limit: 3
    ) {
      edges {
        node {
          title
          publishedDate(formatString: "MMMM Do YYYY")
          slug
          artists {
            englishName
          }
          authors {
            englishName
          }
          category {
            name
          }
          coverImage {
            url
          }
        }
      }
    }
  }
`;

export default FeatureTemplate;
