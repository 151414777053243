import styled from 'styled-components';

export const Banner = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    position: relative;
    top: initial;
    height: auto;
  }
`;

export const Body = styled.div`
  padding: 80px 0 40px;
  margin-bottom: 100vh;
  background-color: white;
  transform: translateY(100vh);
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    padding: 20px 0 40px;
    /* margin-bottom: 0; */
    transform: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-bottom: 0;
  }
`;

export const ImgWrap = styled.div`
  display: none;
  height: 260px;
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    display: block;
    height: 400px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 260px;
  }
`;

export const MobileDetails = styled.div`
  display: none;
  width: 100%;
  margin: 0 auto;
  padding: 0 5% 20px;
  background-color: white;
  p {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: bolder;
    color: lightgray;
  }
  div {
    justify-content: space-between;
    display: flex;
    margin-bottom: -28px;
    p:first-of-type {
      color: black;
    }
    p:last-of-type {
      color: #7454a1;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    display: block;
  }
`;

export const MobileTitle = styled.div`
  display: none;
  width: 100%;
  margin: 0 auto;
  padding: 35px auto;
  padding: 0 5%;
  background-color: white;
  h1 {
    margin: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    display: block;
  }
`;
